// /* Fuentes */
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;900&display=swap');

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: local('Montserrat-Thin'), url('../fonts/Montserrat-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: local('Montserrat-ExtraLight'), url('../fonts/Montserrat-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat-Light'), url('../fonts/Montserrat-Light.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat-Regular'), url('../fonts/Montserrat-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat-Medium'), url('../fonts/Montserrat-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat-SemiBold'), url('../fonts/Montserrat-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat-Bold'), url('../fonts/Montserrat-Bold.woff') format('woff');
}
