.width19por {
  width: 19%;
}

.right {
  right: 0;
}

.left {
  left: 0;
}

.left1bl {
  left: 14px;
}

.align-top {
  vertical-align: top;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.left-auto {
  margin-left: auto;
}

.right-auto {
  margin-right: auto;
}

.position-absolute {
  position: absolute;

}

.center-absolute {
  position: absolute;
  right: 0;
  left: 0;
}

.vertical-absolute {
  top: 50%;
  transform: translateY(-50%);
}

.center {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  display: block;
}

.center-sin-text {
  margin-right: auto;
  margin-left: auto;
  display: block;
}

.flex-center {
  margin-right: auto;
  margin-left: auto;
  display: flex;
}

.flex {
  display: inline-flex;
  text-align: center;
}

.full-height {
  height: 100vh;
}

.circle {
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 19px;
  line-height: 1.75em;
  text-align: center;
  width: 1.75em;
}

.radius {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  line-height: 70px;
  text-align: center;
  width: 90px;
  padding-top: 20px;
}

.radius-0 {
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.alineaciones {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.alineacion-right {
  padding-right: 0 !important;
}

.alineacion-left {
  padding-left: 0 !important;
}

.pad-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pad-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pad-25-lef-ring {
  padding-left: 25px;
  padding-right: 25px;
}

.pad-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.pad-Btm-5 {
  padding-bottom: 5px;
}

.pad-Btm-10 {
  padding-bottom: 10px;
}

.pad-Btm-20 {
  padding-bottom: 20px;
}

.pad-Btm-30 {
  padding-bottom: 30px;
}

.pad-Btm-50 {
  padding-bottom: 50px;
}

.pad-Btm-100 {
  padding-bottom: 100px;
}

.pad-top-5 {
  padding-top: 5px;
}

.pad-top-10 {
  padding-top: 10px;
}

.pad-top-15 {
  padding-top: 15px;
}

.pad-top-20 {
  padding-top: 20px;
}

.pad-top-30 {
  padding-top: 30px;
}

.pad-lt-rt {
  padding-left: 8px;
  padding-right: 8px;
}

.pad {
  padding-right: 0;
  padding-left: 0;
}

.pad-right {
  padding-right: 0px !important;
}

.pad-right1por {
  padding-right: 1%;
}

.pad-right-2-5x {
  padding-right: 2.5%;
}

.pad-right-4 {
  padding-right: 4px !important;
}

.pad-right-10 {
  padding-right: 10px;
}

.pad-right-26xpor {
  padding-right: 26%;
}

.mg-right-21porc {
  margin-right: 21%;
}

.mg-right-25porc {
  margin-right: 11%;
}

.pad-right-35 {
  padding-right: 35px;
}

.pad-right-35xpor {
  padding-right: 31%;
}

.pad-right-45 {
  padding-right: 45px;
}

.pad-lft {
  padding-left: 0px !important;
}

.pad-lft-5 {
  padding-left: 5px;
}

.pad-lft-9 {
  padding-left: 9px;
}

.pad-lft-10 {
  padding-left: 10px;
}

.pad-lft-15 {
  padding-left: 15px !important;
}

.pad-lft-25 {
  padding-left: 25px;
}

.pad-lft-30 {
  padding-left: 30px;
}

.pad-lft-35 {
  padding-left: 35px;
}

.pad-lft-60 {
  padding-left: 60px;
}

.mg-top--5x {
  margin-top: -5px;
}

.mg-top-5 {
  margin-top: 5px;
}

.mg-top-10 {
  margin-top: 10px;
}

.mg-top-15 {
  margin-top: 15px;
}

.mg-top-20imp {
  margin-top: 20px !important;
}

.mg-top-20 {
  margin-top: 20px;
}

.mg-top-30 {
  margin-top: 30px;
}

.mg-top-30imp {
  margin-top: 30px !important;
}

.mg-top-40 {
  margin-top: 40px;
}

.mg-top-50 {
  margin-top: 50px;
}

.mg-top-60 {
  margin-top: 60px;
}

.mg-top-70 {
  margin-top: 70px;
}

.mg-top-80 {
  margin-top: 80px;
}

.mg-top-95 {
  margin-top: 95px !important;
}

.mg-top-99 {
  margin-top: 99px !important;
}

.mg-top-100 {
  margin-top: 100px;
}

.mg-top-140 {
  margin-top: 140px;
}

.mg-top-535 {
  margin-top: 535px;
}

.mg-btm-0 {
  margin-bottom: 0px !important;
}

.mg-btm-5 {
  margin-bottom: 5px !important;
}

.mg-btm-8 {
  margin-bottom: 8px !important
}

.mg-btm-10 {
  margin-bottom: 10px !important
}

.mg-btm-15 {
  margin-bottom: 15px;
}

.mg-btm-20 {
  margin-bottom: 20px;
}

.mg-btm-25 {
  margin-bottom: 25px;
}

.mg-btm-20impor {
  margin-bottom: 20px !important;
}

.mg-btm-30 {
  margin-bottom: 30px;
}

.mg-btm-37 {
  margin-bottom: 37px;
}

.mg-btm-39 {
  margin-bottom: 39px;
}

.mg-btm-40 {
  margin-bottom: 40px;
}

.mg-btm-50 {
  margin-bottom: 50px;
}

.mg-btm-55 {
  margin-bottom: 55px;
}

.mg-btm-60 {
  margin-bottom: 60px;
}

.mg-btm-70 {
  margin-bottom: 70px;
}

.mg-btm-80 {
  margin-bottom: 80px;
}

.mg-btm-100 {
  margin-bottom: 100px;
}

.mg-btm-145 {
  margin-bottom: 145px !important;
}

.mg-left-10 {
  margin-left: 10px;
}

.mg-left--15x {
  margin-left: -15px;
}

.mg-left--10x {
  margin-left: -10px;
}

.mg-left-15 {
  margin-left: 15px;
}

.mg-left-20 {
  margin-left: 10px;
}

.mg-left-21porc {
  margin-left: 20.5%;
}

.mg-left-25porc {
  margin-left: 11%;
}

.mg-left--20x {
  margin-left: -20px;
}

.mg-left--25x {
  margin-left: -25px;
}

.mg-left--30x {
  margin-left: -30px;
}

.mg-left-37 {
  margin-left: 37px;
}

.mg-left-54 {
  margin-left: 54px;
}

.mg-left--60x {
  margin-left: -60px !important;
}

.mg-lft-65 {
  margin-left: 65px;
}

.mg-lft-55 {
  margin-left: 55px !important;
}

.mg-lft-60 {
  margin-left: 60px;
}

.mg-left-5cor {
  margin-left: 5px;
}

.mg-left-110 {
  margin-left: 110px;
}

.mg-right-10 {
  margin-right: 10px;
}

.mg-right-10pr {
  margin-right: 12%;
}

.mg-right--35x {
  margin-right: -35px;
}

.mg {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mg10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mg15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mg25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mg2540 {
  margin-top: 25px;
  margin-bottom: 40px;
}

.mg50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.nowrap {
  flex-wrap: nowrap;
}

.lin-sep25 {
  line-height: 25px;
}

.alg-right {
  display: block;
  margin-left: auto;
  text-align: right;
}

.lef430 {
  left: 430px !important;
}

.fields {
  width: 100%;
}

.width41 {
  width: 41% !important;
}

.width95 {
  width: 95% !important;
}

.width42 {
  width: 42% !important;
}

.width295 {
  width: 295px;
  max-width: 295px;
}

.max-400 {
  max-width: 400px !important;
}

.spacinpalabra {
  word-spacing: 0px !important;
}

.text-right {
  text-align: right;
}

.widthIphone {
  min-width: 320px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  text-align: right;
}

.ml-6 {
  margin-left: 4rem !important;

  @media(max-width: 1100px) {
    margin-left: 2rem !important;
  }

  @media(max-width: 900px) {
    margin-left: 0rem !important;
  }
}

.mr-6 {
  margin-right: 4rem !important;

  @media(max-width: 1100px) {
    margin-left: 2rem !important;
  }

  @media(max-width: 900px) {
    margin-left: 0rem !important;
  }
}

.mt-7 {
  margin-top: 5rem;
}

.maxw28rem {
  max-width: 28rem;
}



