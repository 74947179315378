html {
  // height: 100%;
  // font-size: 100%;
  // overflow-x: hidden !important;
}

a,
span {
  font-family: $montserrat-regular;
  text-decoration: none !important;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  @include font-size(1rem);
}

p {
  font-family: $montserrat-regular;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  @include font-size(1rem);
}

h1 {
  font-family: $montserrat-semiBold;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  @include font-size(2rem);
}

h2 {
  font-family: $montserrat-semiBold;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  @include font-size(1.4rem);
}

h3 {
  font-family: $montserrat-semiBold;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  @include font-size(1.1rem);
}

h4 {
  font-family: $montserrat-semiBold;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  @include font-size(1rem);
}

body {
  overflow-x: hidden !important;
  background-color: $white;
  position: relative;
  background-size: cover;
  height: 100%;
  background-attachment: fixed;
}

strong,
b {
  font-family: $montserrat-medium !important;
  font-weight: 700 !important;
}

p {

  strong,
  b {
    color: #000000;
    font-size: 17px;
  }
}

::-webkit-scrollbar {
  width: 0px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #a1a09f;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.form-control:focus {
  box-shadow: 0 0 0 0.1rem rgb(210 210 210 / 25%);
}

.color-white {
  color: $white;
}

.color-academy {
  color: $academy-color;
}

.color-alliance {
  color: $alliance-color !important;
}

.color-ecosystems {
  color: $ecosystems-color;
}

.color-entrepreneurship {
  color: $entrepreneurship-color !important;
}

.color-l-store {
  color: $l-store-color !important;
}

.color-resources {
  color: $resources-color !important;
}

.color-projects {
  color: $use-cases-color !important;
}

.color-the-blockchain {
  color: $the-blockchain-color;
}

.color-the-academy {
  color: $academy-color;
}

.text-link {
  line-height: 25px;
  font-size: 1.16rem;
}

.container-sites {
  max-width: 1582px;
  width: 100%;
  display: block;
  margin: auto;
  position: relative;
}



.owl-theme .owl-dots .owl-dot span {
  width: 13px;
  height: 13px;
  margin: 5px 7px;
  background: #A5A5A5;
  opacity: 0.24;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #A5A5A5;
  opacity: 1;
}

.owl-theme .owl-nav {
  position: absolute;
  top: 40%;
  width: 91%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media(max-width: 900px) {
    top: 100%;
  }
}

.owl-prev {
  position: absolute;
  background: transparent !important;
  color: #A5A5A5 !important;
  left: -5%;
  font-size: 40px;

  @media(max-width: 1680px) {
    left: -4%;
  }

  @media(max-width: 1440px) {
    left: -4%;
  }

  @media(max-width: 1366px) {
    display: none
  }

  @media(max-width: 800px) {
    display: inline-block;
    position: relative;
    font-size: 30px;
  }
}

.owl-next {
  position: absolute;
  background: transparent !important;
  color: #A5A5A5 !important;
  right: -3%;
  font-size: 40px;

  @media(max-width: 1680px) {
    right: -2%;
  }

  @media(max-width: 1440px) {
    right: -2%;
  }

  @media(max-width: 1366px) {
    display: none
  }

  @media(max-width: 800px) {
    display: inline-block;
    position: relative;
    font-size: 30px;
  }
}

.owl-theme .owl-nav [class*=owl-] {
  font-size: 30px;

}

.owl-carousel .owl-stage-outer {
  @media(max-width: 900px) {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }
}

.owl-dots {
  margin-top: 2rem;

  @media(max-width: 900px) {
    display: none;
  }
}
